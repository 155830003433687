export const getOverviewData = () => {
    return [
        {
            sectionImage: 'Overview/AllegiantStadium-LogoWhite-new.png',
            // sectionTitle: 'Allegiant Stadium',
            paragraph: 'Create an immersive experience and leave a lasting impression on your guests when you takeover Allegiant Stadium. Utilize all event spaces to create a cohesive environment ideal for private concerts, keynotes, and receptions.',
            area: '1.75M',
            reception: '65,000',
            // banquet: '300'
        }
    ]
}

export const getCarouselImages = () => {
    return [
        'AllegiantStadium/Photos/allegiant-stadium-01.jpg',
        'AllegiantStadium/Photos/allegiant-stadium-02.jpg',
        'AllegiantStadium/Photos/allegiant-stadium-03.jpg',
        'AllegiantStadium/Photos/allegiant-stadium-04.jpg',
        'AllegiantStadium/Photos/allegiant-stadium-05.jpg',
        'AllegiantStadium/Photos/allegiant-stadium-06.jpg'
	]
}

export const getLayoutImages = () => {
    return [
        'AllegiantFlightDeck/Layout/placeholder-layout.jpg'
    ]
}
